<template>

    <zw-sidebar @shown="shown" :title="this.payload.id ? $t('common.form.title.editModule')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createModule')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-select-group name="enabled"
                                         v-model="form.enabled"
                                         :label-prefix="labelPrefix"
                                         :options="boolOptions"
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>

                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.modules.label.',
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            boolOptions: {
                0: this.$t('common.button.no'),
                1: this.$t('common.button.yes'),
            },
        }
    },
    methods: {
        ...mapGetters('Modules', ['getModule']),
        shown() {
            this.$store.dispatch('Modules/fetchModule', {
                id: this.payload.id,
            })
                .then((data) => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(this.getModule()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Modules/saveModule', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>